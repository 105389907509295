import Quotes from './Components/Quotes';
import './App.css';

function App() {

return <Quotes />;

}

export default App;
